export const backgColorProperty = {
  1: {
    background: {
      audio: "url(" + require('@/static/course/bg-audio-0.png') + ")",
      ppt: "url(" + require('@/static/course/bg-ppt-0.png') + ")",
      video: "url(" + require('@/static/course/bg-video-0.png') + ")",
      image: "url(" + require("@/static/course/bg-image-0.png") + ")",
      document: "url(" + require('@/static/course/bgd1.png') + ")"
    },
    textcolor: '#EB6756'
  },
  2: {
    background: {
      audio: "url(" + require('@/static/course/bg-audio-1.png') + ")",
      video: "url(" + require('@/static/course/bg-video-1.png') + ")",
      // 
      ppt: "url(" + require('@/static/course/bg-ppt-1.png') + ")",
      image: "url(" + require("@/static/course/bg-image-1.png") + ")",
      document: "url(" + require('@/static/course/bgd2.png') + ")"

    },
    textcolor: '#EB8C56'
  },
  3: {
    background: {
      audio: "url(" + require('@/static/course/bg-audio-2.png') + ")",
      video: "url(" + require('@/static/course/bg-video-2.png') + ")",
      // 
      ppt: "url(" + require('@/static/course/bg-ppt-2.png') + ")",
      image: "url(" + require("@/static/course/bg-image-2.png") + ")",
      document: "url(" + require('@/static/course/bgd3.png') + ")"
    },
    textcolor: '#EBB756'
  },
  4: {
    background: {
      audio: "url(" + require('@/static/course/bg-audio-3.png') + ")",
      video: "url(" + require('@/static/course/bg-video-3.png') + ")",
      ppt: "url(" + require('@/static/course/bg-ppt-3.png') + ")",
      image: "url(" + require("@/static/course/bg-image-3.png") + ")",
      document: "url(" + require('@/static/course/bgd4.png') + ")"
    },
    textcolor: '#4AD9A8'
  },
  5: {
    // 化学   暂时没有
    background: {
      audio: "url(" + require('@/static/course/bg-audio-4.png') + ")",
      // ppt: "url(" + require('@/static/course/bg-ppt-1.png') + ")",
      video: "url(" + require('@/static/course/bg-video-4.png') + ")",
      // 
      ppt: "url(" + require('@/static/course/bg-ppt-0.png') + ")",
      image: "url(" + require("@/static/course/bg-image-0.png") + ")",
      document: "url(" + require('@/static/course/bgd5.png') + ")"
    },
    textcolor: '#EB8C56'
  },
  6: {
    background: {
      audio: "url(" + require('@/static/course/bg-audio-5.png') + ")",
      video: "url(" + require('@/static/course/bg-video-5.png') + ")",
      ppt: "url(" + require('@/static/course/bg-ppt-2.png') + ")",
      image: "url(" + require("@/static/course/bg-image-2.png") + ")",
      document: "url(" + require('@/static/course/bgd6.png') + ")"

    },
    textcolor: '#56ACEB'
  },
  7: {
    background: {
      audio: "url(" + require('@/static/course/bg-audio-6.png') + ")",
      video: "url(" + require('@/static/course/bg-video-6.png') + ")",
      ppt: "url(" + require('@/static/course/bg-ppt-3.png') + ")",
      image: "url(" + require("@/static/course/bg-image-3.png") + ")",
      document: "url(" + require('@/static/course/bgd7.png') + ")"
    },
    textcolor: '#5673EB'
  },
  8: {
    background: {
      audio: "url(" + require('@/static/course/bg-audio-7.png') + ")",
      //ppt: "url(" + require('@/static/course/bg-ppt-1.png') + ")",
      video: "url(" + require('@/static/course/bg-video-7.png') + ")",
      // 
      ppt: "url(" + require('@/static/course/bg-ppt-0.png') + ")",
      image: "url(" + require("@/static/course/bg-image-0.png") + ")",
      document: "url(" + require('@/static/course/bgd1.png') + ")"
    },
    textcolor: '#9456EB'
  },
  10: {
    background: {
      // audio: "url(" + require('@/static/course/bg-audio-1.png') + ")",
      // ppt: "url(" + require('@/static/course/bg-ppt-1.png') + ")",
      // video: "url(" + require('@/static/course/bg-video-1.png') + ")"
    },
    textcolor: '#0D2C78'
  },
}