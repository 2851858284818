<template>
  <div class="lecture">
    <CommonTopSearch ref="CommonTopSearchRef"
                     background="#303FD4"
                     @initData="initData" />

    <!-- 下拉选择框 -->
    <div class="select_subject">
      <dropDown :list='typeList'
                :index='idx'
                attribute_name='name'
                @changeMenu='checkOneChapter' />
    </div>
    <div class='videowrap'>
    
      <Tabs ref="tabsRef" :typeIndex="typeList[idx].value"/>
    
    </div>
    <!-- <OtherForm ref="otherFormRef"
               :list="videoList" /> -->

  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import Tabs from './components/tabs.vue'
import OtherForm from '././components/otherForm.vue'
import { backgoundColor } from '../course/components/background.js'
import { instance } from '@/utils/http.js'
import CommonTopSearch from '../components/COMMON_TOP_SEARCH/index.vue'
import dropDown from '@/views/components/dropdown/index.vue'
import { message } from 'ant-design-vue'

let backgColor = backgoundColor
let router = useRouter()
let idx = ref(0)
let typeList = [
{ name: '文档', value: 'document' },
  { name: '视频', value: 'video' },
  { name: '音频', value: 'audio' },
  { name: '图片', value: 'image' }
]

let videoList = ref([])
let urlList = [
  '/api/v1/teaching/file_lst',
  '/api/v1/teaching/file_lst',
]
let page = 0
let limit = 8
let count = 0
let subject_id = 0
let active = false
let id = ref('')
let keywords = ref('')
let otherFormRef = ref()
let tabsRef = ref()
const refresh = () => {
  initData(id.value, keywords.value)
}
const checkOneChapter = (index) => {
  idx.value = index
  // tabsRef.value.handleToggleResource(typeList[index].value)
  refresh()
}

const getclass = (item) => {
  return `s${item.subject_id}`
}
const checkVideo = (row) => {
  if (idx.value) {
    router.push('/course/preview?type=video&url=' + row.url + "&filename=" + row.filename + "&subject_name=" + row.subject_name)
  } else {
    otherFormRef.value.dialogVisible = true
    otherFormRef.value.subjectId = row.subject_id

  }
}
const initData = async (id1, keywords1) => {
  id.value = id1
  keywords.value = keywords1


  tabsRef.value.handleToggleResource(typeList[idx.value].value, id.value,JSON.parse(window.localStorage.getItem('userInfo')).edu_school_id)

  // if (videoList.value.length == 0) {
  //   message.error('暂无数据~')
  // }

}
</script>

<style lang="scss" scoped>
.lecture {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-image: url("../../static/allBackg/本校资源.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.videowrap {
  height: calc(100vh - 240rem);
  overflow-y: scroll;
  margin-top: 40rem;
}

.videos {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
  width: 100%;
  padding: 10rem 50rem;

  justify-content: space-between;
  .video1 {
    /**布局占位 */
    width: 340rem;
  }
  .video {
    position: relative;
    width: 340rem;
    height: 250rem;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 28rem;
    overflow: hidden;
    .subject {
      font-size: 24rem;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      justify-content: center;
      height: 44rem;
      align-items: center;
    }
    .desc {
      width: calc(100% - 20rem);
      margin: 0 auto;
      margin-top: 10rem;
      text-align: center;
      font-weight: bold;
      font-size: 24rem;
      color: #000000;
      // height: 86rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .star {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
      img {
        font-size: 20rem;
        height: 20rem;
      }
    }
    .btn {
      width: 150rem;
      height: 44rem;
      font-size: 22rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 30rem;
      border-radius: 35rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
      img {
        width: 24rem;
        margin-left: 6rem;
      }
    }
  }
}

.s1 {
  background-image: url("../../static/goodVideo/bg-语文@2x.png");
}
.s7 {
  background-image: url("../../static/goodVideo/bg-道德与法治@2x.png");
}
.s10 {
  background-image: url("../../static/goodVideo/bg-化学@2x.png");
}
.s5 {
  background-image: url("../../static/goodVideo/bg-化学@2x.png");
}
.s6 {
  background-image: url("../../static/goodVideo/bg-历史@2x.png");
}
.s9 {
  background-image: url("../../static/goodVideo/bg-生物@2x.png");
}
.s2 {
  background-image: url("../../static/goodVideo/bg-数学@2x.png");
}
.s4 {
  background-image: url("../../static/goodVideo/bg-物理@2x.png");
}
.s3 {
  background-image: url("../../static/goodVideo/bg-英语@2x.png");
}

.loading_warp {
  display: flex;
  justify-content: center;

  ::v-deep .el-button {
    border: none;
    background: none;
  }
}
::v-deep .is-loading:before {
  opacity: 0;
}

.select_subject {
  position: absolute;
  top: 30rem;
  right: 40rem;
  background: #303fd4;
  border-radius: 35rem;

  /* .mock_btn {
    width: 240rem;
    height: 60rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 32rem;
    font-weight: 500;

    img {
      width: 26rem;
      height: 26rem;
      margin-left: 12rem;
    }
  }*/
}
</style>